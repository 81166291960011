import "./App.css";
import MangaAnnotator from "./MangaAnnotator";

function App() {
  return (
    <div className="App">
      <MangaAnnotator />
    </div>
  );
}

export default App;
