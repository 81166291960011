import React, { useRef, useEffect, useState } from "react";
import { Eye, Globe, X, ClipboardCopy } from "lucide-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Settings from "./Settings";
import { ocr, translate } from "./gpt";
import { useStore, BoundingBox } from "./store";
import { version } from "./version";

const Spinner = () => (
  <div>
    <svg
      className="animate-spin h-10"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <circle
        cx="12"
        cy="12"
        r="5"
        fill="none"
        stroke="#ccc"
        strokeWidth="2"
        strokeLinecap="butt"
      />
      <path
        d="M12 7 A5 5 0 0 1 17 12"
        fill="none"
        stroke="#555"
        strokeWidth="2"
        strokeLinecap="butt"
      />
    </svg>
  </div>
);

interface BoundingBoxItemProps {
  box: BoundingBox;
  index: number;
}

const BoundingBoxItem: React.FC<BoundingBoxItemProps> = ({ box, index }) => {
  const { image, autoOCR, autoTranslate, removeBox, setBox } = useStore();
  const boxCanvasRef = useRef<HTMLCanvasElement>(null);
  const [ocrLoading, setOcrLoading] = useState(false);
  const [translateLoading, setTranslateLoading] = useState(false);

  useEffect(() => {
    const canvas = boxCanvasRef.current;
    if (canvas && image) {
      canvas.height = box.height;
      canvas.width = box.width;
      const ctx = canvas.getContext("2d");
      if (ctx && image instanceof HTMLImageElement) {
        ctx.drawImage(
          image,
          box.x,
          box.y,
          box.width,
          box.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );
      }
    }
  }, [box, image, boxCanvasRef]);

  useEffect(() => {
    if (!box.ocrText && box.isFinished && autoOCR) {
      handleOcr();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [box.ocrText, box.isFinished, index, autoOCR]);

  useEffect(() => {
    if (box.ocrText && !box.translations && autoTranslate) {
      handleTranslate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [box.ocrText, box.translations, autoTranslate]);

  useEffect(() => {
    const resizeTextareas = () => {
      document.querySelectorAll("textarea").forEach((textarea) => {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      });
    };

    resizeTextareas();
    window.addEventListener("resize", resizeTextareas);

    return () => window.removeEventListener("resize", resizeTextareas);
  }, [box.ocrText, box.translations]);

  const handleOcr = async () => {
    setOcrLoading(true);
    const canvas = boxCanvasRef.current;
    if (canvas) {
      const dataUrl = canvas.toDataURL("image/jpg");
      const text = await ocr(dataUrl);

      const newBox = { ...box, ocrText: text || "<api-error>" };
      setBox(index, newBox);
      if (!text.startsWith("<") && autoTranslate) {
        handleTranslateWithText(text);
      }
    }
    setOcrLoading(false);
  };

  const handleTranslate = async () => {
    if (!box.ocrText) return;

    setTranslateLoading(true);
    const translations = await translate(box.ocrText);
    setBox(index, { ...box, translations });
    setTranslateLoading(false);
  };

  const handleTranslateWithText = async (text: string | undefined) => {
    if (!text) return;

    setTranslateLoading(true);
    const translatedText = await translate(text);
    setBox(index, { ...box, ocrText: text, translations: translatedText });
    setTranslateLoading(false);
  };

  const handleOcrTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    const newBox = { ...box, ocrText: newText };
    setBox(index, newBox);
  };

  const handleCopyTranslation = (translation: string | undefined) => {
    if (translation) {
      navigator.clipboard
        .writeText(translation.replace(/\n/g, " "))
        .then(() => {
          // Optionally, you can add some visual feedback here
          console.log("Translation copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy translation: ", err);
        });
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center space-x-4 mb-2">
        <span className="text-lg font-semibold text-gray-700">{index + 1}</span>
        <canvas
          ref={boxCanvasRef}
          className="border border-gray-300 rounded max-h-[150px] max-w-[250px] flex-shrink-0"
        />
        <div className="relative flex-grow">
          <textarea
            value={box.ocrText || ""}
            onChange={handleOcrTextChange}
            className="w-full border border-gray-300 rounded p-2 resize-none overflow-hidden"
            rows={1}
            style={{ height: "auto", minHeight: "2.5rem" }}
            onInput={(e) => {
              e.currentTarget.style.height = "auto";
              e.currentTarget.style.height =
                e.currentTarget.scrollHeight + "px";
            }}
          />
          {ocrLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
              <Spinner />
            </div>
          )}
        </div>
        <div className="flex flex-col items-center space-y-2 flex-shrink-0">
          <button
            onClick={handleOcr}
            className="text-gray-500 hover:text-blue-700 border border-gray-300 rounded p-1"
            aria-label="Perform OCR"
            disabled={ocrLoading}
          >
            <span className="tooltip" data-tooltip="Perform OCR">
              <Eye size={24} />
            </span>
          </button>
          <button
            onClick={handleTranslate}
            className="text-gray-500 hover:text-green-700 border border-gray-300 rounded p-1"
            aria-label="Translate"
            disabled={ocrLoading}
          >
            <span className="tooltip" data-tooltip="Translate">
              <Globe size={24} />
            </span>
          </button>
          <button
            onClick={() => removeBox(index)}
            className="text-gray-500 hover:text-red-700 border border-gray-300 rounded p-1"
            aria-label="Remove box"
          >
            <X size={24} />
          </button>
        </div>
      </div>
      <div className="flex flex-col space-y-2 items-start w-full">
        {box.translations ? (
          box.translations.map((translation, i) => (
            <div key={i} className="relative w-full">
              <textarea
                value={translation || ""}
                onChange={(e) => {
                  const newTranslations = [...box.translations];
                  newTranslations[i] = e.target.value;
                  setBox(index, { ...box, translations: newTranslations });
                }}
                className="w-full border border-gray-300 rounded p-2 resize-none overflow-hidden"
                rows={1}
                style={{ height: "auto", minHeight: "2.5rem" }}
                onInput={(e) => {
                  e.currentTarget.style.height = "auto";
                  e.currentTarget.style.height =
                    e.currentTarget.scrollHeight + "px";
                }}
              />
              <button
                onClick={() => handleCopyTranslation(translation)}
                className="absolute top-1 right-1 text-gray-500 hover:text-purple-700"
                aria-label="Copy translation"
              >
                <ClipboardCopy size={16} />
              </button>
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center w-full">
            <button
              onClick={handleTranslate}
              className="text-gray-500 hover:text-green-700 border border-gray-300 rounded p-2"
              disabled={translateLoading}
            >
              {translateLoading ? <Spinner /> : "Translate"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const BoundingBoxList = () => {
  const { boxes, setBoxes } = useStore();

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const reorderedBoxes = Array.from(boxes);
    const [removed] = reorderedBoxes.splice(result.source.index, 1);
    reorderedBoxes.splice(result.destination.index, 0, removed);
    setBoxes(reorderedBoxes);
  };

  const clearAllBoxes = () => setBoxes([]);

  return (
    <div className="ml-4 w-1/2 bg-white rounded-lg shadow-md p-6">
      <div className="absolute top-2 right-2">
        <Settings />
      </div>
      <h3 className="text-2xl font-bold mb-4 text-gray-800">
        SkyScan v{version}
      </h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-boxes">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="space-y-4"
            >
              {boxes.map((box: BoundingBox, index: number) => (
                <Draggable
                  key={`box-${index}`}
                  draggableId={`box-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="flex items-center justify-between bg-gray-50 p-4 rounded-lg shadow-sm"
                    >
                      <BoundingBoxItem box={box} index={index} />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      {boxes.length > 0 && (
        <button
          onClick={clearAllBoxes}
          className="mb-4 p-2 border border-1 border-red-500 text-red-500 rounded hover:bg-red-100 mt-4"
        >
          Clear All
        </button>
      )}
    </div>
  );
};