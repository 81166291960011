import React, { useState } from "react";
import { Settings as SettingsIcon } from "lucide-react";
import { useStore } from "./store";
import { updateApiKey } from "./gpt";
import { ReleaseNotes } from "./version"; // Add this import

const Settings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const apiKey = useStore((state) => state.openaiApiKey);
  const setApiKey = useStore((state) => state.setOpenaiApiKey);
  const autoOCR = useStore((state) => state.autoOCR);
  const setAutoOCR = useStore((state) => state.setAutoOCR);
  const autoTranslate = useStore((state) => state.autoTranslate);
  const setAutoTranslate = useStore((state) => state.setAutoTranslate);

  const handleSave = () => {
    setApiKey(apiKey);
    updateApiKey();
    setIsOpen(false);
  };

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        className="p-2 bg-gray-200 rounded-full"
      >
        <SettingsIcon size={24} />
      </button>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[750px] max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-6">Settings</h2>
            <div className="mb-4 flex items-center">
              <label className="block text-sm font-medium text-gray-700 mr-4 w-1/4">
                OpenAI API Key
              </label>
              <input
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className="p-2 border rounded w-3/4"
              />
            </div>
            <div className="mb-6 flex items-center">
              <label className="block text-sm font-medium text-gray-700 mr-4 w-1/4">
                Auto OCR
              </label>
              <input
                type="checkbox"
                checked={autoOCR}
                onChange={(e) => setAutoOCR(e.target.checked)}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </div>
            <div className="mb-4 flex items-center">
              <label className="block text-sm font-medium text-gray-700 mr-4 w-1/4">
                Auto Translate
              </label>
              <input
                type="checkbox"
                checked={autoTranslate}
                onChange={(e) => setAutoTranslate(e.target.checked)}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </div>
            <div className="flex justify-center mb-6">
              <button
                onClick={handleSave}
                className="p-2 pl-4 pr-4 bg-blue-500 text-white rounded mr-4"
              >
                Save
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className="p-2 pl-4 pr-4 bg-gray-500 text-white rounded"
              >
                Cancel
              </button>
            </div>
            <ReleaseNotes />
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
