import { create } from "zustand";
import { persist } from "zustand/middleware";

export type BoundingBox = {
  x: number;
  y: number;
  width: number;
  height: number;
  ocrText?: string;
  translations: string[];
  isFinished: boolean;
};

type StoreState = {
  image: HTMLImageElement | null;
  boxes: BoundingBox[];
  isDrawing: boolean;
  startPos: { x: number; y: number };
  hoveredBoxIndex: number | null;
  isCtrlPressed: boolean;
  openaiApiKey: string;
  autoOCR: boolean;
  autoTranslate: boolean;
  setOpenaiApiKey: (key: string) => void;
  setAutoOCR: (autoOCR: boolean) => void;
  setAutoTranslate: (autoTranslate: boolean) => void;
  setImage: (image: HTMLImageElement | null) => void;
  setBoxes: (boxes: BoundingBox[]) => void;
  setBox: (index: number, box: BoundingBox) => void;
  setIsDrawing: (isDrawing: boolean) => void;
  setStartPos: (pos: { x: number; y: number }) => void;
  setHoveredBoxIndex: (index: number | null) => void;
  setIsCtrlPressed: (isCtrlPressed: boolean) => void;
  removeBox: (index: number) => void;
};

export const useStore = create<StoreState>()(
  persist(
    (set) => ({
      image: null,
      boxes: [],
      isDrawing: false,
      startPos: { x: 0, y: 0 },
      hoveredBoxIndex: null,
      isCtrlPressed: false,
      openaiApiKey: "",
      autoOCR: false,
      autoTranslate: false,
      setOpenaiApiKey: (key) => set({ openaiApiKey: key }),
      setAutoOCR: (autoOCR) => set({ autoOCR }),
      setAutoTranslate: (autoTranslate) => set({ autoTranslate }),
      setImage: (image) => set({ image }),
      setBoxes: (boxes) => set({ boxes }),
      setBox: (index, box) =>
        set((state) => ({
          boxes: state.boxes.map((b, i) => (i === index ? box : b)),
        })),
      setIsDrawing: (isDrawing) => set({ isDrawing }),
      setStartPos: (pos) => set({ startPos: pos }),
      setHoveredBoxIndex: (index) => set({ hoveredBoxIndex: index }),
      setIsCtrlPressed: (isCtrlPressed) => set({ isCtrlPressed }),
      removeBox: (index) =>
        set((state) => ({
          boxes: state.boxes.filter((_, i) => i !== index),
        })),
    }),
    {
      name: "store", // unique name for the storage
      partialize: (state) => ({
        image: state.image,
        boxes: state.boxes,
        openaiApiKey: state.openaiApiKey,
        autoOCR: state.autoOCR,
        autoTranslate: state.autoTranslate,
      }),
    },
  ),
);
