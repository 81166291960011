import OpenAI from "openai";
import { useStore } from "./store";

const openai = new OpenAI({
  // baseURL: "http://localhost:8010/proxy",
  apiKey: useStore.getState().openaiApiKey, // This is the default and can be omitted
  dangerouslyAllowBrowser: true,
});

// async function main() {
//     console.log(localStorage.getItem('openai_api_key'));
//     const chatCompletion = await openai.chat.completions.create({
//         messages: [{ role: 'user', content: 'Say this is a test' }],
//         model: 'gpt-3.5-turbo',
//     });
//     console.log(chatCompletion.choices[0].message.content);
// }

function updateApiKey() {
  openai.apiKey = useStore.getState().openaiApiKey;
}

async function ocr(image: string): Promise<string> {
  // const systemPrompt = "Perform OCR on images. Only return the text. Return <error> if there is no text";
  // const systemPrompt = "Perform OCR on images. Only return the text in a single line. Return <error> if there is no text";
  const systemPrompt =
    "Perform OCR on images. Only return the text, return the text in a single line unless it's a paragraph. Return <error> if there is no text";
  // const userPrompt = `Perform OCR`;

  console.log("Calling openai...");

  try {
    const chatCompletion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: [
            // { type: 'text', text: userPrompt },
            { type: "image_url", image_url: { url: image, detail: "low" } },
          ],
        },
      ],
      temperature: 1,
      model: "gpt-4o",
      seed: 27,
    });

    if (chatCompletion.usage) {
      // log cost
      let inputCostPerToken = 5.0 / 10000;
      let outputCostPerToken = 15.0 / 10000;
      let inputCost = chatCompletion.usage.prompt_tokens * inputCostPerToken;
      let outputCost =
        chatCompletion.usage.completion_tokens * outputCostPerToken;

      console.log(
        `Total cost (${chatCompletion.usage.prompt_tokens}/${chatCompletion.usage.completion_tokens}): ${inputCost + outputCost} cents`,
      );
    }

    return (
      chatCompletion.choices[0].message.content?.toLowerCase() || "<empty>"
    );
  } catch (error) {
    console.error(error);
    return "<api-error>";
  }
}

async function translate(text: string): Promise<string[]> {
  // replace newlines if it's single with spaces
  text = text.replace(/(?<!\n)\n(?!\n)/g, " ");
  // const systemPrompt = "Translate text from English to Neutral Spanish, for hispanoamerican and spanish culture. Text is from a Japanese Manga, be as accurate as possible. Keep the text in uppercase. Provide three different translations. Return a JSON list of translations. Return <error> if there is no text, return the same text if it can't be translated. JSON format: {\"translations\": [\"translation1\", \"translation2\", ...]}";

  const systemPrompt = `You are a translator specializing in English to Neutral Spanish translation for hispanoamerican and spanish culture. Your task is to translate the following text from a Japanese Manga:
Instructions:
1. Translate the text into Neutral Spanish, aiming for accuracy and cultural appropriateness.
2. Keep all text in lowercase.
3. Provide three different translations.
4. If there is no text to translate, return <error>.
5. If the text cannot be translated, return the original text.

Output your translations in this JSON format:
{"translations": ["TRANSLATION1", "TRANSLATION2", "TRANSLATION3"]}`;

  const userPrompt = `${text}`;

  console.log("Calling openai...");

  console.log(systemPrompt + "\n" + userPrompt);

  try {
    const chatCompletion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ],
      temperature: 1,
      model: "gpt-4o",
      seed: 27,
      response_format: { type: "json_object" },
    });

    const responseContent =
      chatCompletion.choices[0].message.content || "<empty>";
    var translations = JSON.parse(responseContent);

    var output = "";

    for (var i = 0; i < translations.translations.length; i++) {
      output += `${i + 1}. ${translations.translations[i]}\n`;
    }

    console.log(output);

    return translations.translations.map((translation: string) =>
      translation.toLowerCase(),
    );
  } catch (error) {
    console.error(error);
    return ["<api-error>", "<api-error>", "<api-error>"];
  }
}

export { ocr, translate, updateApiKey };
