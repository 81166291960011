const version = "5";

const releaseNotes = `
v0
    - Primera version
v1
    - Añade control + z para borrar el último cuadrado hecho. (Igual que la X de la derecha)
v2
    - Añade 💬 como favicon
    - Cambia el título a SkyScan {version}
    - La traducción ahora tiene un tamaño de fuente más pequeño
v3
    - Escribe todo el texto en minúsculas
v4
    - Añade tres opciones de traducción
v5
    - Ctrl + z no borra el recuadrosi estás modificando el texto
`;

const ReleaseNotes = () => {
  return (
    <div className="mt-4">
      <h3 className="text-lg font-semibold mb-2 text-left">Release Notes</h3>
      <pre className="whitespace-pre-wrap text-sm text-left">
        {releaseNotes}
      </pre>
    </div>
  );
};

export { version, releaseNotes, ReleaseNotes };
