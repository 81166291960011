import React, { useRef, useEffect, useCallback } from "react";
import { useStore, BoundingBox } from "./store"; // Import useStore and BoundingBox type
import { BoundingBoxList } from "./BoundingBoxList";

// Define the minimum size for the bounding box
const MIN_SIZE = 50;

const TEXT_OFFSET_X = 5;
const TEXT_OFFSET_Y = 45;
const TEXT_FONT = "50px Arial";
const TEXT_COLOR = "red";
const TEXT_STROKE_COLOR = "white";
const TEXT_STROKE_WIDTH = 8;

const ImageUploader = ({
  onImageUpload,
}: {
  onImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <div className="mb-4">
    <input
      type="file"
      accept="image/*"
      onChange={onImageUpload}
      className="p-2 border rounded w-full"
    />
  </div>
);

const MangaAnnotator = () => {
  const {
    image,
    setImage,
    boxes,
    setBoxes,
    setBox,
    isDrawing,
    setIsDrawing,
    startPos,
    setStartPos,
    hoveredBoxIndex,
    setHoveredBoxIndex,
    isCtrlPressed,
    setIsCtrlPressed,
    removeBox,
  } = useStore();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef(null);

  const removeLastBox = useCallback(() => {
    setBoxes(boxes.slice(0, -1));
  }, [setBoxes, boxes]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Control") {
        setIsCtrlPressed(true);
      }
      if (e.ctrlKey && e.key === "z") {
        // Check if the active element is not a textarea or input
        if (
          document.activeElement?.tagName !== "TEXTAREA" &&
          document.activeElement?.tagName !== "INPUT"
        ) {
          e.preventDefault();
          removeLastBox();
        }
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Control") {
        setIsCtrlPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [setIsCtrlPressed, removeLastBox]); // Added removeLastBox dependency

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        setImage(img);
        setBoxes([]); // Clear boxes when a new image is uploaded
        localStorage.setItem("image", img.src);
      };
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  };

  const drawText = (
    ctx: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
  ) => {
    ctx.font = TEXT_FONT;
    ctx.strokeStyle = TEXT_STROKE_COLOR;
    ctx.lineWidth = TEXT_STROKE_WIDTH;
    ctx.strokeText(text, x + TEXT_OFFSET_X, y + TEXT_OFFSET_Y);

    ctx.fillStyle = TEXT_COLOR;
    ctx.fillText(text, x + TEXT_OFFSET_X, y + TEXT_OFFSET_Y);
  };

  const redrawCanvas = useCallback(() => {
    if (!canvasRef.current || !image || !image.src) return;
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    boxes.forEach((box: BoundingBox, index: number) => {
      ctx.strokeStyle =
        box.width < MIN_SIZE || box.height < MIN_SIZE
          ? "grey"
          : index === hoveredBoxIndex
            ? "grey"
            : "red";
      ctx.lineWidth = 2;
      ctx.strokeRect(box.x, box.y, box.width, box.height);
      if (box.width >= MIN_SIZE && box.height >= MIN_SIZE) {
        drawText(ctx, (index + 1).toString(), box.x, box.y);
      }
    });
    ctx.setTransform(1, 0, 0, 1, 0, 0);
  }, [image, boxes, hoveredBoxIndex]);

  useEffect(() => {
    if (image && canvasRef.current && containerRef.current) {
      const canvas = canvasRef.current as HTMLCanvasElement;
      canvas.width = image.width;
      canvas.height = image.height;

      redrawCanvas();
    }
  }, [image, boxes, redrawCanvas]);

  const getScaledCoordinates = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const rect = canvas.getBoundingClientRect();

    if (!image) {
      return { x: 0, y: 0 };
    }

    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    return {
      x: (e.clientX - rect.left) * scaleX,
      y: (e.clientY - rect.top) * scaleY,
    };
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (isCtrlPressed && hoveredBoxIndex !== null) {
      removeBox(hoveredBoxIndex);
    } else {
      if (!image) return;
      const { x, y } = getScaledCoordinates(e);
      setStartPos({ x, y });
      setIsDrawing(true);
      setBoxes([
        ...boxes,
        {
          x,
          y,
          width: 0,
          height: 0,
          ocrText: "",
          translations: [],
          isFinished: false,
        },
      ]);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (isCtrlPressed) {
      const { x, y } = getScaledCoordinates(e);
      let smallestBoxIndex = null;
      let smallestBoxSize = Infinity;

      boxes.forEach((box, index) => {
        if (
          x >= box.x &&
          x <= box.x + box.width &&
          y >= box.y &&
          y <= box.y + box.height
        ) {
          const boxSize = box.width * box.height;
          if (boxSize < smallestBoxSize) {
            smallestBoxSize = boxSize;
            smallestBoxIndex = index;
          }
        }
      });

      setHoveredBoxIndex(smallestBoxIndex);
    } else {
      if (!isDrawing || !image) return;
      const { x, y } = getScaledCoordinates(e);
      const newBox = {
        x: Math.min(startPos.x, x),
        y: Math.min(startPos.y, y),
        width: Math.abs(x - startPos.x),
        height: Math.abs(y - startPos.y),
        translations: [],
        isFinished: false,
      };

      setBox(boxes.length - 1, newBox);
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    setBoxes(
      boxes.filter((box) => box.width >= MIN_SIZE && box.height >= MIN_SIZE),
    );
    setBox(boxes.length - 1, { ...boxes[boxes.length - 1], isFinished: true });
  };

  return (
    <div className="flex">
      <div className="w-1/2" ref={containerRef}>
        <ImageUploader onImageUpload={handleImageUpload} />
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          className="border w-full"
        />
      </div>
      <BoundingBoxList />
    </div>
  );
};

export default MangaAnnotator;
